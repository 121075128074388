.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mission-statement {
  margin-left: 15%;
  margin-right: 15%;
  font-size: 1em;
  line-height: calc(1ex / 0.32);
  text-align: left;
}


html, body {
  height: 100%;
}

body {
  display: flex;
  margin: auto;
  justify-content: center;
  color: #333;
}

.container {
  margin: 100px 15px;
  max-width: 670px;
}

@media screen and (min-width: 600px) {
  .container {
    padding: 0 30px;
  }
}

p {
  font-size: 16px;
  line-height: 26px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
@media screen and (min-width: 600px) {
  p {
    font-size: 18px;
    line-height: 30px;
  }
}